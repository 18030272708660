<script setup>
import {useTranslation,} from '@/assets/js/src/util/composables/useTranslation'

const show = defineModel({
    type: Boolean,
    default: false,
})

defineProps({
    title: {
        type: String,
        default: '',
    },
    body: {
        type: String,
        default: '',
    },
    cancel: {
        type: String,
        default: '',
    },
    confirm: {
        type: String,
        default: '',
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    loading: {
        type: Boolean,
        default: false,
    },
})

defineEmits([
    'cancel',
    'confirm',
])

const {t,} = useTranslation()
</script>

<template>
    <v-dialog
        v-model="show"
        class="bs-dialog__simple"
        persistent
        max-width="400"
        attach=".v-application"
        @keydown.esc="$emit('cancel')"
    >
        <v-card class="pt-2">
            <v-card-title class="text-h5">
                {{ title }}
            </v-card-title>
            <v-card-text>
                <slot>{{ body }}</slot>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn
                    variant="text"
                    @click.stop="$emit('cancel', $event)"
                >
                    {{ cancel || t('async.common.button.cancel') }}
                </v-btn>
                <v-btn
                    color="primary"
                    :disabled="disabled"
                    :loading="loading"
                    variant="flat"
                    @click.stop="$emit('confirm', $event)"
                >
                    {{ confirm || t('async.common.button.confirm') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<style lang="scss">
    .bs-app {
        .v-overlay--active {
            z-index: 10000 !important;
        }

        .v-dialog__content--active {
            z-index: 10001 !important;
        }
        
        .v-dialog {
            max-height: 100vh;
            max-height: calc(var(--vh) * 100);
            margin: 0 auto;
        }
    }
</style>